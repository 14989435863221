export default [
  {
    title: "MDA Reports",
    reports: [
      {
        title: "Street View",
        subtitles: [
          {
            label: "Street View",
            url: "MDA/STREET-VIEW/street-view",
          },
        ],
      },
    ],
  },
  {
    title: "Analytic Reports",
    reports: [],
  },
];
