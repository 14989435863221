/* eslint-disable @typescript-eslint/no-explicit-any */
// import { Link as LinkButton } from "react-router-dom";
import { Grid, Paper as PaperBase, Typography, Link } from "@mui/material";
import { Link as LinkBase, LinkProps } from "react-router-dom";
import { styled } from "@mui/material/styles";

const Container = styled(Grid)(({ theme }) => ({
  marginTop: theme.reportCard.padding,
  alignItems: "flex-start",
}));

const Paper = styled(PaperBase)(({ theme }) => ({
  border: theme.reportCard.border,
  padding: theme.reportCard.padding,
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: theme.reportCard.fontSize,
  textAlign: "left",
  marginTop: theme.reportCard.padding,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.reportCard.padding,
  fontWeight: "bold",
  textAlign: "left",
  color: "black",
}));

const CustomLink: React.ComponentType<LinkProps> =
  LinkBase as React.ComponentType<LinkProps>;

const TitleLink = styled(CustomLink)(({ theme }) => ({
  display: "block",
  paddingTop: theme.reportCard.padding,
  paddingLeft: theme.reportCard.padding,
  fontSize: theme.reportCard.padding,
  fontWeight: "bold",
  textAlign: "left",
  textDecoration: "none",
  color: theme.reportCard.color,
  undefined: "none",
}));

const TitleWithoutLink = styled(Typography)(({ theme }) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: "1.42rem",
  textAlign: "left",
  marginRight: theme.spacing(1),
  opacity: 0.8,
}));

const SubTitle = styled(Link)(({ theme }) => ({
  display: "block",
  fontSize: theme.reportCard.padding,
  fontWeight: "bold",
  textAlign: "left",
  marginTop: theme.reportCard.padding,
  marginLeft: theme.reportCard.padding,
  cursor: "pointer",
  color: theme.reportCard.color,
}));

const Content = styled(Typography)(({ theme }) => ({
  fontSize: theme.reportCard.padding,
  textAlign: "left",
  paddingTop: theme.reportCard.padding,
}));

export default {
  Container,
  Grid,
  Paper,
  Label,
  Title,
  TitleWithoutLink,
  TitleLink,
  SubTitle,
  Content,
};
