import * as React from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// styles
import SC from "./dialog.styles";

export interface DialogFormProps {
  open: boolean;
  handleClose: () => void;
  onGenerateReport: (rnid: string) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DialogForm = ({
  open,
  handleClose,
  onGenerateReport,
}: DialogFormProps) => {
  const [error, setError] = React.useState(false);
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSubmit: (event: any) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const formJson = Object.fromEntries((formData as any).entries());
            const { rnid } = formJson;
            if (rnid.startsWith("IND") || rnid.startsWith("NON")) {
              setError(false);
              onGenerateReport(rnid);
            } else {
              setError(true);
            }
          },
        }}
      >
        <DialogTitle> Generate Report </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: 16 }}>
            Please enter Rnid to generate the report.
          </DialogContentText>
          <DialogContentText style={{ fontSize: 12, fontWeight: "bold" }}>
            This may take around a minute, please wait. The file will
            automatically download, then this window will close.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="rnid"
            label={error ? "Enter Valid Rnid " : "Enter Rnid."}
            type="text"
            fullWidth
            variant="standard"
            error={error}
            onInput={() => setError(false)}
          />
        </DialogContent>
        <DialogActions>
          <SC.Button variant="outlined" onClick={handleClose}>
            Cancel
          </SC.Button>
          <SC.Button variant="contained" type="submit">
            {" "}
            Generate Report{" "}
          </SC.Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogForm;
