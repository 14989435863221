/* eslint-disable no-alert */
// Dependencies
import React, { useEffect, useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rpt_IndividualRegistrationArgs,
  Order_By,
  Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column,
  useGetPaginatedRegIndividualQuery,
  useUraIndividualFilterQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  TableFetchDataFunction,
  INITIAL_ROWS_PER_PAGE,
} from "components/table/table.component";
import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";
import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";

// Schema
import {
  individualTableColumns,
  getAccessorType,
  IndividualRegistrationTableFilters,
  DEFAULT_FILTER_ACCESSOR,
} from "./street-view.schema";

// Assets
import SC from "./street-view.styles";

interface IndividualRegistrationTableProps {
  displayHeader?: boolean;
  defaultFilters?: IndividualRegistrationTableFilters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
  onLoaded?: () => void;
  title?: string;
}

const TABLE_NAME = "rtcs_db_FnRPT_IndividualRegistration";

export const IndividualRegistrationTable: React.FC<IndividualRegistrationTableProps> =
  React.memo(
    ({
      defaultFilters,
      displayHeader,
      initialRowsPerPage = INITIAL_ROWS_PER_PAGE.REPORTS,
      maxHeight,
      noDataComponent,
      onLoaded,
      title = "Street View",
    }) => {
      const defaultFilterConditions = useMemo(
        () =>
          getConditionsFromDefaultFilters(
            defaultFilters ?? {},
            DEFAULT_FILTER_ACCESSOR,
          ),
        [defaultFilters],
      );
      const hasUnhandledDefaultFilter = useMemo(
        () =>
          getHasUnhandledDefaultFilter(
            defaultFilters ?? {},
            DEFAULT_FILTER_ACCESSOR,
          ),
        [defaultFilters],
      );
      const [filterConditions, setFilterConditions] = React.useState<
        FilterCondition[]
      >([] as FilterCondition[]);
      const [queryVariables, setQueryVariables] =
        React.useState<Query_RootRtcs_Db_Ph_Rpt_IndividualRegistrationArgs>({
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        });

      const [pageIndexResetSignal, setPageIndexResetSignal] =
        React.useState(false);

      const { data, loading, error } = useGetPaginatedRegIndividualQuery({
        context: { clientName: CLIENT_NAME.HASURA },
        skip: !queryVariables || hasUnhandledDefaultFilter,
        variables: {
          params: jsonToStringQueryVariables(queryVariables ?? {}),
          aggregateParams: jsonToStringQueryVariables({
            where: queryVariables?.where,
          }),
        },
      });

      const [exportDataFn, { loading: exportLoading, error: exportError }] =
        useExportData(TABLE_NAME, queryVariables);

      useAppErrorHandler(error || exportError);

      const individualData = React.useMemo(() => {
        const individual = data?.rtcs_db_FnRPT_IndividualRegistration ?? [];

        return individual;
      }, [data]);

      const columns = useMemo(() => individualTableColumns, []);

      const universalColumns: ColumnsProps[] = useMemo(() => {
        return columns.map((column, i) => {
          return {
            index: i,
            label: `${column.header}`,
            value: `${column.accessorKey}`,
            type: `${getAccessorType(column.accessorKey)}`,
          };
        });
      }, [columns]);

      const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
        const columnObject = condition[Object.keys(condition)[0]];
        if (columnObject[Object.keys(columnObject)[0]] === "") {
          // eslint-disable-next-line no-param-reassign
          condition = {};
        }
        setFilterConditions([condition]);
        setPageIndexResetSignal((previousSignal) => !previousSignal);
      };

      const handlePaginatedFetchData: TableFetchDataFunction =
        React.useCallback(({ pageIndex, pageSize, sortBy }) => {
          const defaultSortColumn = {
            id: Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column.TinNo,
            desc: true,
          };
          const sortByColumn =
            sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
          setQueryVariables((oldVariables) => ({
            ...oldVariables,
            orderBy: {
              [sortByColumn.id]: sortByColumn.desc
                ? Order_By.Desc
                : Order_By.Asc,
            },
            limit: pageSize,
            offset: pageIndex * pageSize,
          }));
        }, []);

      const totalRowsCount = hasUnhandledDefaultFilter
        ? 0
        : parseInt(
            data?.rtcs_db_FnRPT_IndividualRegistration_aggregatecm[0].value ??
              "-1",
            10,
          );

      const paginationControlled = React.useMemo(
        () => ({
          fetchData: handlePaginatedFetchData,
          loading,
          totalRowsCount,
        }),
        [totalRowsCount, loading, handlePaginatedFetchData],
      );

      // TODO: Replace for useLazyQuery when the function has been updated
      // to return a Promise instead of void.
      // https://github.com/apollographql/react-apollo/issues/3499#issuecomment-639954192
      const { refetch: IndividualFilterFn } = useUraIndividualFilterQuery({
        skip: true,
        context: {
          clientName: CLIENT_NAME.HASURA,
        },
      });

      const fetchSuggestions = React.useCallback(
        async (value: string, column: string) => {
          try {
            let suggestions: string[] = [];

            if (value !== "") {
              const filterVariables: Query_RootRtcs_Db_Ph_Rpt_IndividualRegistrationArgs =
                {
                  where: { [column]: { _ilike: `${value}%` } },
                  distinct_on: [
                    column as Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column,
                  ],
                  limit: 10,
                };
              const individualFilterData = await IndividualFilterFn({
                params: jsonToStringQueryVariables(filterVariables),
              });
              suggestions =
                individualFilterData.data?.rtcs_db_FnRPT_IndividualRegistration.map(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (option) => option[column],
                );
            }

            // eslint-disable-next-line @typescript-eslint/return-await
            return Promise.resolve((suggestions ?? []) as string[]);
          } catch (err) {
            return Promise.reject(err);
          }
        },
        [IndividualFilterFn],
      );

      const filterInputs: TableFilterInput[] = React.useMemo(() => {
        return [
          {
            type: TableFilterType.AUTOFILL,
            label: "TIN No.",
            columnName:
              Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column.TinNo,
            fetchSuggestions,
          },
          {
            type: TableFilterType.AUTOFILL,
            label: "First Name",
            columnName:
              Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column.FirstName,
            fetchSuggestions,
          },
          {
            type: TableFilterType.AUTOFILL,
            label: "Surname",
            columnName:
              Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column.SurName,
            fetchSuggestions,
          },
          {
            type: TableFilterType.AUTOFILL,
            label: "Mobile No.",
            columnName:
              Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column.MobileNumber,
            fetchSuggestions,
          },
          {
            type: TableFilterType.AUTOFILL,
            label: "Email Id",
            columnName:
              Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column.EmailId,
            fetchSuggestions,
          },
          {
            type: TableFilterType.SELECT,
            label: "Is Income Source Rental?",
            columnName:
              Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column.IsIncomeSrcRental,
            options: [
              { key: "1", label: "All", value: "" },
              { key: "2", label: "Y", value: "Y" },
              { key: "3", label: "N", value: "N" },
            ],
          },
        ];
      }, [fetchSuggestions]);

      const onTableFilterApply = React.useCallback(
        (conditions: FilterCondition[]) => {
          setFilterConditions(conditions);
          setPageIndexResetSignal((previousSignal) => !previousSignal);
        },
        [],
      );

      useEffect(() => {
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        }));
      }, [defaultFilterConditions, filterConditions]);
      useEffect(() => {
        if (!loading) onLoaded?.();
      }, [loading, onLoaded]);

      return (
        <>
          <Table
            actionsOnRight={[
              "hide/show-columns",
              "filter-results",
              "export-to-excel-sheet/csv",
            ]}
            title={title}
            columns={columns}
            data={individualData}
            onAction={() => alert("under construction")}
            headerPanel={
              displayHeader ? <SC.Title>MDA - Street View</SC.Title> : undefined
            }
            leftPanel={
              <TableFilter
                filterInputs={filterInputs}
                onFilterApply={onTableFilterApply}
                universalFilterColumns={universalColumns}
                onUniversalFilterSubmit={onUniversalFilterer}
              />
            }
            persistenceId="40db3f68-d66d-4258-a689-e0d2f2eea71b"
            paginationControlled={paginationControlled}
            stickyHeader
            initialRowsPerPage={initialRowsPerPage}
            pageIndexResetSignal={pageIndexResetSignal}
            exportData={exportDataFn}
            maxHeight={maxHeight}
            noDataComponent={noDataComponent}
          />
          <ProgressIndicator open={loading || exportLoading} />
        </>
      );
    },
  );

const StreetView: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb label="Street View" />
      <IndividualRegistrationTable displayHeader />
    </SC.Box>
  );
};

export default StreetView;
