import { Button as ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Button = styled(ButtonBase)(({ theme }) => ({
  height: "1.8rem",
  color: theme.table.toolbarActions.iconColor,
  borderColor: theme.table.toolbarActions.iconColor,
  textTransform: "capitalize",
  "&:hover": {
    borderColor: theme.table.toolbarActions.iconColor,
  },

  "&.MuiButton-contained": {
    color: theme.palette.background.default,
    backgroundColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
  },
}));

export default {
  Button,
};
