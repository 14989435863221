/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid,
  Paper as PaperBase,
  Typography,
  Box as BoxBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const Paper = styled(PaperBase)(() => ({
  border: "0.07rem solid rgba(0,0,0, 0.12)",
  padding: "1.1rem",
}));

const ScrollView = styled(BoxBase)(() => ({
  margin: "0px",
  padding: "0px",
  height: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  textAlign: "left",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: "1.42rem",
  textAlign: "left",
  marginRight: theme.spacing(1),
  opacity: 0.8,
}));

const Content = styled(Typography)(({ theme }) => ({
  fontSize: theme.reportCard.padding,
  textAlign: "left",
  paddingTop: theme.reportCard.padding,
}));

export default {
  Grid,
  Paper,
  Label,
  ScrollView,
  Content,
};
