// Dependencies
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import usePrevious from "@react-hook/previous";

// Pages
import MainPage from "pages/main/main.component";
import LoginPage from "pages/login/login.component";
import ResetPassword from "pages/reset-password/reset-password.component";
import ForgotPassword from "pages/forgot-password/forgot-password.component";
import OtpVerification from "pages/otp-verification/otp-verification.component";
import EmailVerification from "pages/email-verification/email-verification.component";

// Components
import ErrorBoundary from "components/error-boundary/error-boundary.component";

// Graphql
import auth from "graphql/authentication";

// Assets
import SC from "./app.styles";

const App: React.FC = () => {
  const { isLoggedIn } = auth.useAuthenticatioData();
  const { isLoggedIn: isOtpLoggedIn } = auth.useOtpAuthenticatioData();

  // Save the URL you copied and pasted in a new tab or window in the browser, or in case you have reloaded the page.
  const previousUrl = usePrevious(window.location.pathname);
  // If the URL you copied, pasted or reloaded is the same as "/" or "/login" it will redirect you to the home page after logging in,
  // otherwise it will redirect you to the page you want to access before logging in.
  const url =
    previousUrl === "/" || previousUrl === "/login" ? "/home" : previousUrl;

  return (
    <SC.App>
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route
              path="/login"
              element={
                isLoggedIn ? (
                  <Navigate to={`${url}`} />
                ) : isOtpLoggedIn ? (
                  <Navigate to="/otp-verification" />
                ) : (
                  <LoginPage />
                )
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/email-verification" element={<EmailVerification />} />
            <Route
              path="/otp-verification"
              element={
                isOtpLoggedIn ? <OtpVerification /> : <Navigate to="/home" />
              }
            />
            <Route
              path="*"
              element={isLoggedIn ? <MainPage /> : <Navigate to="/login" />}
            />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </SC.App>
  );
};

export default App;
