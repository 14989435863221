import { makeVar } from "@apollo/client";

export const TOKEN_KEY = "token";
export const OTP_TOKEN_KEY = "otpToken";
export const OTP_EXPIRY_KEY = "endTime";

export interface AuthenticationData {
  isLoggedIn: boolean;
  token: string | null;
}

export const initialAuthenticationData: AuthenticationData = {
  isLoggedIn: !!sessionStorage.getItem(TOKEN_KEY),
  token: sessionStorage.getItem(TOKEN_KEY),
};

export const otpAuthenticationData: AuthenticationData = {
  isLoggedIn: !!sessionStorage.getItem(OTP_TOKEN_KEY),
  token: sessionStorage.getItem(OTP_TOKEN_KEY),
};

export const authenticationDataVar = makeVar(initialAuthenticationData);

export const otpAuthenticationDataVar = makeVar(otpAuthenticationData);

export default {
  initialAuthenticationData,
  authenticationDataVar,
  otpAuthenticationDataVar,
};
