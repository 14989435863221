// Dependencies
import { useReactiveVar } from "@apollo/client";
import React, { useState, useEffect } from "react";

import client from "./client";
import {
  TOKEN_KEY,
  OTP_TOKEN_KEY,
  OTP_EXPIRY_KEY,
  AuthenticationData,
  otpAuthenticationData,
  authenticationDataVar,
  otpAuthenticationDataVar,
} from "./authentication.utils";

export const useAuthenticatioData = (): AuthenticationData => {
  const authData = useReactiveVar(authenticationDataVar);
  return authData;
};

export const useOtpAuthenticatioData = (): AuthenticationData => {
  const authData = useReactiveVar(otpAuthenticationDataVar);
  return authData;
};

export const useCountdown = (initialTime: number): number => {
  const [countdown, setCountdown] = useState<number>(initialTime);

  useEffect(() => {
    const savedEndTime = localStorage.getItem(OTP_EXPIRY_KEY);
    let endTime: number;

    if (savedEndTime && !isNaN(Date.parse(savedEndTime))) {
      // Valid date in localStorage
      endTime = new Date(savedEndTime).getTime();
    } else {
      // Set a new 5-minute countdown
      endTime = new Date().getTime() + 5 * 60 * 1000;
      localStorage.setItem(OTP_EXPIRY_KEY, new Date(endTime).toISOString());
    }

    const updateCountdown = () => {
      const currentTime = new Date().getTime();
      const remainingTime = Math.max(
        0,
        Math.floor((endTime - currentTime) / 1000),
      );
      setCountdown(remainingTime);
    };

    // Initial update to set the correct countdown value
    updateCountdown();

    // Update every second
    const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer);
  }, [initialTime]);

  return countdown;
};

export const logIn = (token: string): void => {
  // remove otp expiry key if it exists
  localStorage.removeItem(OTP_EXPIRY_KEY);
  sessionStorage.removeItem(OTP_TOKEN_KEY);

  // set token
  sessionStorage.setItem(TOKEN_KEY, token);
  const authData: AuthenticationData = {
    isLoggedIn: !!token,
    token,
  };
  authenticationDataVar(authData);
};

export const logOut = (): void => {
  sessionStorage.removeItem(TOKEN_KEY);
  const authData: AuthenticationData = {
    isLoggedIn: false,
    token: null,
  };
  authenticationDataVar(authData);
  client.clearStore();
};

export const otpLogIn = (token: string): void => {
  // check if otp expiry key is already set, if set delete it
  if (localStorage.getItem(OTP_EXPIRY_KEY)) {
    localStorage.removeItem(OTP_EXPIRY_KEY);
  }
  sessionStorage.setItem(OTP_TOKEN_KEY, token);

  const authData: AuthenticationData = {
    isLoggedIn: !!token,
    token,
  };
  otpAuthenticationDataVar(authData);
};

// check if Otp exists in the JWT token if exists return the boolean value
export const isOtpJwt = (token: string): boolean => {
  const payload = token.split(".")[1];
  const decodedPayload = atob(payload);
  const { Otp } = JSON.parse(decodedPayload);

  return !!Otp;
};

export const getEmailFromJWTToken = (): string | null => {
  const otpExpiryKey = useOtpAuthenticatioData().token;

  if (!otpExpiryKey) {
    return null;
  }

  const payload = otpExpiryKey.split(".")[1];
  const decodedPayload = atob(payload);
  const { sub: email } = JSON.parse(decodedPayload);

  return email;
};

export const otpLogOut = (): void => {
  sessionStorage.removeItem(OTP_TOKEN_KEY);
  const authData: AuthenticationData = {
    isLoggedIn: false,
    token: null,
  };
  otpAuthenticationDataVar(authData);
  client.clearStore();
};

export default {
  authenticationDataVar,
  useAuthenticatioData,
  logIn,
  logOut,
  useOtpAuthenticatioData,
  useCountdown,
  otpLogIn,
  otpLogOut,
  getEmailFromJWTToken,
  isOtpJwt,
};
